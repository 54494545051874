import { Link } from 'react-router-dom';
import { apiStatusesCombine } from '../../../utils';
import { usePathsStore } from '../../../store/pathsStore/usePathsStore';
import useFetchHotelSelective from '../../../features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from '../../../features/translations/hooks/useFetchTranslationsSelective';
import MainLoader from '../../common/Loaders/MainLoader/MainLoader';
import SearchForm from '../../forms/SearchForm/SearchForm';

import './HeroWithSearchForm.scss';

const HeroWithSearchForm = () => {
  const localizedPaths = usePathsStore(state => state.paths);

  const hotelFields = ['name', 'slogan_main', 'slogan_additional', 'head_photo_url', { village_data: ['caption'] }];
  const { data: hotel, status: hotelStatus } = useFetchHotelSelective(-1, hotelFields);
  const translationWords = ['ReadMore', 'VarBookNow', 'VarSeeAllOffers'];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  if (apiStatusesCombine(translationsStatus, hotelStatus) === 'error') return <></>;
  else if (apiStatusesCombine(translationsStatus, hotelStatus) === 'loading') return <MainLoader />;

  return (
    <main id="content" className="site-main">
      <section className="home-banner-section">
        <div className="home-banner-items">
          <div className="banner-inner-wrap" style={{ backgroundImage: `url(${hotel?.head_photo_url})` }}></div>
          <div className="banner-content-wrap">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-7 col-lg-8 ">
                  <div className="banner-content section-heading section-heading-white">
                    <h5>{hotel?.slogan_main}</h5>
                    <h1 className="banner-title">
                      <b>{hotel?.name} </b>
                      <span>{hotel?.village_data?.caption}</span>
                    </h1>

                    <div className="slider-button">
                      <Link to={localizedPaths.hotel} className="button-primary  button-booknow-desctop">
                        {translations?.ReadMore}
                      </Link>
                      <Link to={localizedPaths.booking} className="button-primary button-booknow-mobile ">
                        {translations?.VarBookNow}
                      </Link>
                      <Link to={localizedPaths.prices} className="button-secondary">
                        {translations?.VarSeeAllOffers}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-lg-4 search-form-home">
                  <SearchForm skipTitle={true} />
                </div>
              </div>
            </div>
          </div>
          <div className="overlay"></div>
        </div>
      </section>
    </main>
  );
};

export default HeroWithSearchForm;
