import { useQuery } from 'react-query';
import { endPoints, reactQuerySlowChangingConfig } from '../../../api/api.config';
import { getApiLang } from '../../../api/api.calls';
import { HotelModel } from '../hotel';
import { useLanguageStore } from '../../../store/languageStore/useLanguageStore';
import { useActiveHotelStore } from '../../../store/activeHotelStore/useActiveHotelStore';
import { usePersonalDataStore } from '../../../store/personalDataStore/usePersonalDataStore';

/*This function won't send an http request if not necessary.
 * So we can use this function to sync states in different components
 * */
export default function useFetchHotel(id: number = -1) {
  const activeLanguage = useLanguageStore(state => state.language.activeLanguage);
  const activeHotelId = useActiveHotelStore(state => state.activeHotel.hotelId);
  const personalData = usePersonalDataStore(state => state.personalData);

  if (id === -1) id = activeHotelId;

  console.log('useFetchHotel called with :', id);

  const r = useQuery(
    'hotel-' + id + '-' + activeLanguage + (personalData?.myCountryCode ? '-' + personalData?.myCountryCode : ''),
    () => GetHotel(id, activeLanguage, personalData?.myCountryCode),
    reactQuerySlowChangingConfig,
  );

  return r;
}

/**
 * Helper function that returns response.data only
 * @returns something
 */
async function GetHotel(id: number, language: string, myCountryCode?: string) {
  const promise = getApiLang<HotelModel>(
    endPoints.hotels,
    id,
    language,
    '*',
    myCountryCode
      ? {
          myCountryCode,
        }
      : null,
  );

  const dataPromise = promise.then(response => response.data);

  return dataPromise;
}
