import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { usePathsStore } from '../../../store/pathsStore/usePathsStore';
import useFetchHotelSelective from '../../../features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from '../../../features/translations/hooks/useFetchTranslationsSelective';

import Navbarmenu from '../Navbarmenu/Navbarmenu';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import SwitchLanguage from '../LanguageSwitch/SwitchLanguage';
import SearchForm from '../../forms/SearchForm/SearchForm';
import HeroWithSearchForm from '../../common/HeroWithSearchForm/HeroWithSearchForm';
import HorizontalLoader from '../../common/Loaders/HorizontalLoader/HorizontalLoader';

import './Header.scss';

import logoProfiWhite from '../../../assets/images/logoprofi1.png';
import logoProfi from '../../../assets/images/logoprofi3.png';

const Header = () => {
  const location = useLocation();
  const pathname = location.pathname ? location.pathname : '';
  const addonclassHeader = pathname === '/' ? 'header-primary' : 'header-primary-white';
  const addonclassHeroimage = pathname === '/' ? 'home-banner-section' : 'home-banner-none';
  const addonclassMenu = pathname === '/' ? 'menuwhite' : 'menudark';
  const logoPic = pathname === '/' ? `${logoProfiWhite}` : `${logoProfi}`;

  const displayHero = pathname === '/' ? true : false;
  const localizedPaths = usePathsStore(state => state.paths);

  const translationWords = ['VarBookNow', 'ReadMore', 'VarBookNow', 'VarSeeAllOffers'];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  const hotelFields = ['name', 'slogan_main', 'slogan_additional'];
  const { data: hotel, status: hotelStatus } = useFetchHotelSelective(-1, hotelFields);

  if (translationsStatus === 'error') return <></>;

  return (
    <>
      {translationsStatus === 'loading' || hotelStatus === 'loading' ? (
        <HorizontalLoader height="30"></HorizontalLoader>
      ) : (
        <>
          <header id="masthead" className={`site-header ${addonclassHeader}`}>
            <div className="top-header">
              <div className="container">
                <div className="row" style={{ position: 'relative' }}>
                  <div className="col-10 d-none d-block">
                    <div className="header-contact-info">
                      <ul>
                        <li>
                          <a href="/">
                            <i className="fas fa-phone-alt"></i> +359 888 398 270
                          </a>
                        </li>
                        <li className="company-email">
                          <a href="mailto:info@Travel.com">
                            <i className="fas fa-envelope"></i> booking@lionbansko.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <form method="get" id="languages">
                      <LanguageSwitch />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-header ">
              <div className="menuouter container d-flex justify-content-between align-items-center">
                <div className="site-identity  d-lg-block">
                  <h2 className="site-title">
                    <Link to="/">
                      <img className="white-logo" src={logoPic} alt="logo" />
                    </Link>
                  </h2>
                  <h1 className="site-title">
                    <a href={localizedPaths.home}>{hotel?.name}</a>
                  </h1>
                </div>

                <Navbarmenu className={addonclassMenu} localizedPaths={localizedPaths} />

                <div className="header-btn  d-lg-block">
                  <Link to={localizedPaths.booking}>{translations?.VarBookNow}</Link>
                </div>
              </div>
            </div>
          </header>

          {displayHero ? <HeroWithSearchForm /> : ''}
        </>
      )}
    </>
  );
};

export default Header;
