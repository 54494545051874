import { PathsType } from './pathsType';
export const defaultPathNames: PathsType = {
  home: '/',
  hotel: '/p/hotel.html',
  facilities: '/p/facilities.html',
  rooms: '/p/rooms.html',
  booking: '/p/booking.html',
  gallery: '/p/gallery.html',
  location: '/p/location.html',
  prices: '/p/prices.html',
  transfers: '/p/transfers.html',
  transferBooking: '/p/transfers-booking.html',
  skipacks: '/p/skipacks.html',
  skipacksBooking: '/p/skipacks-booking.html',
  faq: '/p/faq.html',
  translations: '/translations',
  errorNotFound: '/error404',
  errorNoData: '/errornodata',
  room: '/rooms-accommodation/:roomUrl.html',
  test: '/set-hotel/:newHotelId',
  dev1: '/dev1.html',
  htmlPageWithLangFiledirParentdir: '/:lang/:parentdir/:filedir/:pageUrl.html',
  htmlPageWithLangFiledir: '/:lang/:filedir/:pageUrl.html',
  htmlPageWithLang: '/:lang/:pageUrl.html',
  htmlPage: '/:pageUrl.html',
  dev2: '/dev2',
  dev3: '/dev3',
  dev4: '/dev4',
  dev5: '/dev5',
  dev6: '/dev6',
  searchResults: '/p/search.html',
};
