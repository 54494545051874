import { TranslationModel } from '../features/translations/translation';
import { api, endPoints } from './api.config';

export async function getAllApi<T>(endpoint: string) {
  return await api.get<T[]>(`${endpoint}`);
}

export async function getApi<T>(endpoint: string, id: number) {
  return await api.get<T>(`${endpoint}/${id}`);
}

export async function getAllApiLang<T>(endpoint: string, language: string, filters: any = null) {
  //console.log('getAllApiLang filters: ', filters);

  return await api.get<T[]>(`${endpoint}/?lang=${language}`, { params: { filters } });
}

export async function getApiLang<T>(
  endpoint: string,
  id: number,
  language: string,
  populate: string = '*',
  filters: any = null,
) {
  return await api.get<T>(`${endpoint}/${id}?lang=${language}&populate=${populate}`, { params: { filters } });
}

export async function deleteApi<T>(endpoint: string, id: number) {
  return await api.delete<T>(`${endpoint}/${id}`);
}

export async function postApi<T>(endpoint: string, arg: T) {
  return await api.post<T>(`${endpoint}`, arg);
}

export async function putApi<QT, BT>(endpoint: string, id: string, arg: BT) {
  return await api.put<QT>(`${endpoint}/${id}`, arg);
}
