import { useState } from 'react';
import { generatePath } from 'react-router';
import { NavLink, Link } from 'react-router-dom';
import { FiAlignRight, FiXCircle, FiChevronDown } from 'react-icons/fi';

import useFetchTranslationsSelective from '../../../features/translations/hooks/useFetchTranslationsSelective';
import useFetchHotelSelective from '../../../features/hotels/hooks/useFetchHotelSelective';

import './Menu.scss';
import './Navbarmenu.scss';

const Navbarmenu = ({ className, localizedPaths }) => {
  console.log('Navbarmenu localizedPaths:', localizedPaths);
  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);
  const toggleClass = () => {
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };

  const translationWords = [
    'Main_home',
    'VarFacilities',
    'VarRooms',
    'VarLocation',
    'GalleryName',
    'VarPrices',
    'VarTransfers',
    'VarSkiPackages',
    'VarBook',
    'VarHotelAccommodationOffers',
    'ModeButtonPricesDiscounts',
  ];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);

  const hotelFields = ['rooms_data'];
  const { data: hotel, status: hotelStatus } = useFetchHotelSelective(-1, hotelFields);

  let boxClass = ['main-menu menu-right menuq1'];
  if (isMenu) {
    boxClass.push('menuq2');
  } else {
    boxClass.push('');
  }

  const [isMenuSubMenu, setMenuSubMenu] = useState(false);

  const [menuWithSubmenuIndex, setMenuWithSubmenuIndex] = useState(0);

  const toggleSubmenu = submenuIndex => {
    setMenuWithSubmenuIndex(menuWithSubmenuIndex === submenuIndex ? 0 : submenuIndex);
  };

  const composeSubMenuClass = menuIndex => {
    let boxClassSubMenu = ['sub__menus'];
    if (menuIndex === menuWithSubmenuIndex) {
      boxClassSubMenu.push('sub__menus__Active');
    } else {
      boxClassSubMenu.push('');
    }
    return boxClassSubMenu;
  };

  let boxClassSubMenu = ['sub__menus'];
  if (isMenuSubMenu) {
    boxClassSubMenu.push('sub__menus__Active');
  } else {
    boxClassSubMenu.push('');
  }

  /* all NavLink components below had activeClassName="is-active" property  */
  return (
    <div className={`menu ${className}`}>
      <header className="header__middle">
        <div className="container">
          <div className="row">
            <div className="header__middle__menus">
              <nav className="main-nav ">
                {/* Responsive Menu Button */}
                {isResponsiveclose === true ? (
                  <>
                    <span className="menubar__button mb2" style={{ display: 'none' }} onClick={toggleClass}>
                      {' '}
                      <FiXCircle />{' '}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass}>
                      {' '}
                      <FiAlignRight />{' '}
                    </span>
                  </>
                )}

                <ul className={boxClass.join(' ')}>
                  <li className="menu-item">
                    <NavLink exact="true" onClick={toggleClass} to={localizedPaths.home}>
                      {' '}
                      {translations?.Main_home}{' '}
                    </NavLink>
                  </li>
                  <li className="menu-item ">
                    <NavLink onClick={toggleClass} to={localizedPaths.facilities}>
                      {' '}
                      {translations?.VarFacilities}{' '}
                    </NavLink>{' '}
                  </li>
                  <li onClick={() => toggleSubmenu(1)} className="menu-item sub__menus__arrows">
                    {' '}
                    <Link to={localizedPaths.rooms}>
                      {' '}
                      {translations?.VarRooms}
                      <FiChevronDown />{' '}
                    </Link>
                    <ul className={composeSubMenuClass(1).join(' ')}>
                      {hotel?.rooms_data
                        ? Object.entries(hotel.rooms_data)
                            .filter(([roomId, room]) => {
                              return room.alias_of_id === 0;
                            })
                            .sort((room1, room2) => {
                              return room1?.[1]?.max_adults === 1 || room1?.[1]?.max_adults > room2?.[1]?.max_adults
                                ? 1
                                : -1;
                            })
                            .map(([roomId, room]) => (
                              <NavLink
                                key={room?.id}
                                onClick={toggleClass}
                                to={generatePath(localizedPaths.room, { roomUrl: room.url })}
                              >
                                {room.name}
                              </NavLink>
                            ))
                        : ''}
                    </ul>
                  </li>
                  <li className="menu-item ">
                    <NavLink onClick={toggleClass} to={localizedPaths.location}>
                      {' '}
                      {translations?.VarLocation}{' '}
                    </NavLink>{' '}
                  </li>
                  <li className="menu-item ">
                    <NavLink onClick={toggleClass} to={localizedPaths.gallery}>
                      {' '}
                      {translations?.GalleryName}{' '}
                    </NavLink>{' '}
                  </li>
                  <li onClick={() => toggleSubmenu(2)} className="menu-item sub__menus__arrows">
                    {' '}
                    <Link to={localizedPaths.prices}>
                      {' '}
                      {translations?.VarPrices}
                      <FiChevronDown />{' '}
                    </Link>
                    <ul className={composeSubMenuClass(2).join(' ')}>
                      <NavLink onClick={toggleClass} to={localizedPaths.prices}>
                        {translations?.ModeButtonPricesDiscounts}
                      </NavLink>
                      <NavLink onClick={toggleClass} to={localizedPaths.transfers}>
                        {translations?.VarTransfers}
                      </NavLink>
                      <NavLink onClick={toggleClass} to={localizedPaths.transferBooking}>
                        {translations?.VarTransfers} {translations?.VarBook}
                      </NavLink>
                      <NavLink onClick={toggleClass} to={localizedPaths.skipacks}>
                        {translations?.VarSkiPackages}
                      </NavLink>
                      <NavLink onClick={toggleClass} to={localizedPaths.searchResults}>
                        {translations?.VarHotelAccommodationOffers}
                      </NavLink>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbarmenu;
