import { useState, useEffect } from 'react';
import { AppLanguages, AppFlags } from '../../../const';
import useFetchHotelSelective from '../../../features/hotels/hooks/useFetchHotelSelective';
import useFetchTranslationsSelective from '../../../features/translations/hooks/useFetchTranslationsSelective';
import useGetGeoLocation from '../../../features/location/hooks/useGetGeoLocation';
import { useLanguageStore } from '../../../store/languageStore/useLanguageStore';
import { usePathsStore } from '../../../store/pathsStore/usePathsStore';
import { localizedPathNames } from '../../../store/pathsStore/localizedPathNames';

const LanguageSwitch = () => {
  const [isOpen, setOpen] = useState(false);
  const activeLanguage = useLanguageStore(state => state.language.activeLanguage);
  const { setLanguage } = useLanguageStore();

  const setPaths = usePathsStore(state => state.setPaths);

  const translationWords = [
    'UrlModeKeyFacts',
    'UrlModeRooms',
    'UrlModeBooking',
    'UrlModePhotos',
    'UrlModeAddress',
    'UrlModePricesDiscounts',
    'UrlModeSkiPackages',
    'UrlModeTransfers',
    'VarSearch',
  ];
  const { data: translations, status: translationsStatus } = useFetchTranslationsSelective(translationWords);
  const hotelFields = ['url', { village_data: ['url'] }];
  const { data: hotel, status: hotelStatus } = useFetchHotelSelective(-1, hotelFields);

  useEffect(() => {
    if (translations && hotel) {
      setPaths(localizedPathNames({ ...translations, VillageUrl: hotel.village_data.url, HotelUrl: hotel.url }));
    }
  }, [translations, hotel, activeLanguage]);

  const toggleDropdown = () => setOpen(!isOpen);

  const languageChange = (event, toLAnguage) => {
    event.preventDefault();
    if (activeLanguage !== toLAnguage) {
      setLanguage(toLAnguage);
    }
    setOpen(!isOpen);
  };

  return (
    <div className="languadge-dropdown">
      <div className="dropdown-header" onClick={toggleDropdown}>
        {' '}
        <img src={AppFlags[activeLanguage]} width="22" height="13" />
        <span>{AppLanguages[activeLanguage]}</span>
        <i className={`fa fa-chevron-right icon ${isOpen && 'open'}`}></i>
      </div>
      <div className={`dropdown-body ${isOpen && 'open'}`}>
        {Object.entries(AppLanguages).map(([langCode, langTitle]) => (
          <a href="#" className="dropdown-item" onClick={e => languageChange(e, langCode)} key={langCode}>
            <img src={AppFlags[langCode]} alt={langTitle} width="22" height="13" />
            <span>{langTitle}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default LanguageSwitch;
