import create from 'zustand';
import produce, { Draft } from 'immer';
import { ActiveHotelType } from './activeHotelType';
import { persist } from '../persist';
import { mountStoreDevtool } from 'simple-zustand-devtools';

export const defaultHotelId = 1165; // Chrousso village

export type ActiveHotelStoreType = {
  activeHotel: ActiveHotelType;
  setActiveHotel: (hotelId: number) => void;
};

export const useActiveHotelStore = create<ActiveHotelStoreType>(
  persist(
    {
      key: 'activeHotelStore',
    },
    (set): ActiveHotelStoreType => ({
      activeHotel: {
        hotelId: defaultHotelId,
      },
      setActiveHotel: (hotelId: number) =>
        set(
          produce((draft: Draft<ActiveHotelStoreType>) => {
            draft.activeHotel.hotelId = hotelId;
          }),
        ),
    }),
  ),
);

mountStoreDevtool('activeHotelStore', useActiveHotelStore as any);
